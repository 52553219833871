<template>
    <div>
        <input
            ref="uploadInput"
            type="file"
            @change="uploadDoc"
            hidden
            accept="image/*">
        <v-progress-linear
            :active="loading"
            :value="upload_progress"
            color="primary"
        ></v-progress-linear>
        <v-row justify="center" >
            <v-col cols="7" sm="5" md="5">

                <v-img v-if="imageData" width="100%"
                     :src="imageData"
                     lazy-src="akokomarket.imgix.net/imgs/products/placeholder/no_image.png?w=10"
                >
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>

                <v-img v-else width="100%"
                    :src="`${imgHost}imgs/products/${placeholder.product_type? placeholder.product_type.slug:'placeholder' }/${placeholder.product_type?placeholder.img_url:'no_image.png'}`"
                    :lazy-src="`${imgHost}imgs/products/${placeholder.product_type? placeholder.product_type.slug:'placeholder' }/${placeholder.product_type?placeholder.img_url:'no_image.png'}?w=20`"
                >
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>
        </v-row>
        <v-row
            justify="space-between"
        >
            <v-col cols="12" sm="6">
                <v-btn
                    :disabled="!slug"
                    @click="openDefaultImages"
                    text
                    color="primary"
                    :loading="loading"
                    :percentage="upload_progress"
                    block
                >
                    <v-icon v-if="icon" left>{{imageData || isUpdate ? 'mdi-refresh' : icon}}</v-icon>
                    {{defaultCounter == 0 ? 'use default Image' : 'next default image'}}
                </v-btn>
                <!-- <v-btn
                    :disabled="!slug"
                    @click="fetchDefaultImage"
                    text
                    color="primary"
                    :loading="loading"
                    :percentage="upload_progress"
                    block
                >
                    <v-icon v-if="icon" left>{{imageData || isUpdate ? 'mdi-refresh' : icon}}</v-icon>
                    {{defaultCounter == 0 ? 'use default Image' : 'next default image'}}
                </v-btn> -->
            </v-col>
            <v-col cols="12" sm="6">
                <v-btn
                    @click="$refs.uploadInput.click()"
                    color="primary"
                    :loading="loading"
                    :percentage="upload_progress"
                    block
                    >
                        <v-icon v-if="icon" left>{{imageData || isUpdate ? 'mdi-refresh' : icon}}</v-icon>
                        {{imageData || isUpdate ? 'Change Image' : ( label || 'Upload')}}
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog
            v-model="picturePicker"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Default Images</v-card-title>

                <v-card-text>
                    <v-row v-if="!picturePickerLoading" justify="space-between">
                        <v-col cols="5" sm="4" md="3" v-for="(image, index) in defaultImages" :key="index">
                            <v-img @click="setImage(image)"
                            width="100%" :src="image.url" style="border-radius: 15px; cursor: pointer"></v-img>
                        </v-col>
                    </v-row>
                    <v-row v-else justify="center">
                        <v-progress-circular  indeterminate size="64"></v-progress-circular>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="green darken-1"
                    text
                    @click="picturePicker = false"
                >
                    Cancel
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
    </div>
</template>
<script>
import {eventBus} from "../../main";
export default {
    name: 'FirebaseUpload',
    props: ['isUpdate','icon', 'label', 'name', 'placeholder'],
    data(){
        return{
            defaultCounter: 0,
            picturePicker: false,
            picturePickerLoading: false,
            defaultImages: [],
            imageData: null,
            slug: "",
            picture: null,
            upload_progress: 0,
            loading: false,
            fileName: ''
        }
    },
    methods:{
        previewImage(event) {
            this.upload_progress = 0;
        },

        setImage(image){
            this.$emit('change', {...image, name: this.name})
            this.imageData = image.url
            this.picturePicker = false
        },
        openDefaultImages(){
            this.defaultImages = []
            this.picturePicker = true
            // this.picturePickerLoading = true
            for(let c = 1; c < 11; c++){
                this.firebase.storage().ref(`imgs/products/${this.slug}/${c}.png`)
                    .getDownloadURL()
                    .then(url=>{this.defaultImages.push({url, fileName: `${c}.png`})})
                    .catch(err=>{
                        console.error(err)
                    })
            }
            // this.firebase.storage().ref(`imgs/products/${this.slug}`).listAll()
            //     .then(res=>{
            //         res.items.forEach((ref, i)=>{
            //             if(i < 12){
            //                 ref.getDownloadURL().then((url)=>{
            //                     this.defaultImages.push({url, fileName: ref.name})
            //                 })
            //             }
            //         })
            //         console.log(res)
            //     }).catch(err=>{
            //         console.error(err)
            //     }).finally(_=>{
            //         this.picturePickerLoading = false
            //     })
        },

        fetchDefaultImage(){
            this.loading = true
            if(this.defaultCounter < 1 || this.defaultCounter > 10){
                this.defaultCounter = 1
            }
            this.fileName = `${this.defaultCounter}.png`
            let ref = this.firebase.storage().ref(`imgs/products/${this.slug}/${this.fileName}`)
            ref.getDownloadURL().then((url)=>{
                this.setImage({url, fileName: this.fileName})
                this.loading = false
                this.defaultCounter++
            }).catch(err=>{
                if(err.code == 'storage/object-not-found'){
                    this.defaultCounter = 1
                    this.fileName = `${this.defaultCounter}.png`
                    let ref = this.firebase.storage().ref(`imgs/products/${this.slug}/${this.fileName}`)
                    ref.getDownloadURL().then((url)=>{
                        this.setImage({url, fileName: this.fileName})
                        this.loading = false
                        this.defaultCounter++
                    })
                }else{

                }
            })
        },
        uploadDoc(event){
            this.loading = true
            const upDoc = event.target.files[0]
            const extension = upDoc.name.split('.').pop()
            this.fileName = `${Date.now()}.${extension}`

            const storageRef = this.firebase.storage().ref(`imgs/products/${this.slug}/${this.fileName}`).put(upDoc)

            storageRef.on(`state_changed`,
                snapshot=>{
                    let data_progress = (snapshot.bytesTransferred/snapshot.totalBytes)
                    this.upload_progress = data_progress * 100;
                },
                error=>{
                    console.log(error.message)
                },
                ()=>{
                    this.upload_progress = 100
                    this.loading = false
                    storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                        this.$emit('change', {url, fileName: this.fileName, name: this.name})
                        this.imageData = url
                })
            })
        }
    },
    mounted(){
        eventBus.$on('setProductSlug',(e=>{
            this.slug = e;
        }))
    },
    created(){
        if(this.placeholder)
            this.fileName = this.placeholder
    },
    destroyed(){
    }
}
</script>
