<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout
    >
      <ApolloQuery style="width:100%" :query="getQuery" :variables="variables">
        <ApolloSubscribeToMore
            v-if="subscriptionQuery"
            :document="subscriptionQuery"
            :updateQuery="onSubscriptionUpdate"
        />
        <template v-slot="{result: {data, error}, isLoading}">
          <v-skeleton-loader
              v-if="isLoading"
              transition="fade-transition"
              style="width: 100%"
              type="table"
          >
          </v-skeleton-loader>
          <v-alert v-if="error" class=" white--text" color="error">
            <v-icon left color="white">mdi-alert</v-icon>
            {{ error }}
          </v-alert>
          <v-data-table
              v-if="data"
              :headers="headers"
              :items="data[result_name]"
              sort-by="first_name"
              class="elevation-1 col-12"
              :search="search_phrase"
          >
            <template v-slot:top>
              <v-toolbar color="primary" style=" border-radius : 7px" class="white--text mb-3">
                <template v-if="!isMobile">
                  <v-toolbar-title>
                    <span class=" text-capitalize">{{ title }}</span>
                    <br/>
                    <span
                        class="subheading font-weight-bold caption"
                    >
                      List of all items
                    </span>
                  </v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                </template>
                <v-text-field
                    v-model="search_phrase"
                    @keypress.enter.prevent="searchData"
                    dark
                    color="white"
                    filled
                    dense
                    rounded
                    append-icon="mdi-magnify"
                    :placeholder="`Search ${title}`"
                    class="mt-7"
                    @click:append="searchData"
                />
                <v-spacer></v-spacer>
                <!-- ADD Item  -->
                <v-dialog v-if="createFields.length > 0" v-model="create_item_dialog" max-width="520px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-if="!hide_add_button"
                           rounded color="white" class="elevation-4 primary--text" v-on="on">
                      <v-icon left v-if="!isMobile">{{ icon }}-plus</v-icon>
                      <span>add {{ !isMobile ? title : '' }}</span>
                    </v-btn>
                    <!-- <v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn> -->
                  </template>
                  <v-card>
                    <v-card-title>
                      <v-row justify="center">
                        <span class="headline">{{ formTitle }}</span>
                        <v-btn style="position: absolute; float: right; right: -10px" icon color="primary" class="mr-3" @click="create_item_dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col :cols="field.width ? field.width : 12" v-for="(field, index) in createFields"
                                 :key="index">
                            <firebase-Upload
                                :isUpdate="is_update"
                                :placeholder="editedItem"
                                v-if="field.type === 'upload'"
                                :icon="field.icon"
                                :label="field.label"
                                :name="field.name"
                                @change="setURL"
                            >
                            </firebase-Upload>
                            <v-select
                                @change="field.onChange ? field.onChange(field ,editedItem,createFields) : ()=>{}"
                                :loading=field.loading
                                v-else-if="field.type==='select'"
                                v-model="editedItem[field.name]"
                                :label="field.label"
                                :items="field.items"
                                filled
                                :value="editedItem[field.name]"
                                item-value="value"
                                item-text="text"
                                :prepend-icon="field.icon"
                            ></v-select>
                            <v-text-field
                                @keyup.enter="save"
                                v-else
                                :type="field.type === 'float' ? 'number' : field.type"
                                :min="field.min"
                                :max="field.max"
                                :maxlength="field.max"
                                filled
                                :prepend-icon="field.icon"
                                v-model="editedItem[field.name]"
                                :label="field.label"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>


                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn rounded color="grey" text @click="close">Cancel</v-btn>
                      <v-btn :disabled="isDisabled" :loading="is_saving" rounded color="primary" @click="save">
                        Save
                        <v-icon right>mdi-content-save</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.transaction.final_amount="{item}">
              <v-chip
                  class="ma-2 white--text"
                  :color="item.transaction ? 'success' : 'grey'"
              >{{ item.transaction ? item.transaction.final_amount : 'unpaid' | currency }}
              </v-chip>
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip
                  class="ma-2"
                  :color="item.status === 'pending' ? 'error' : null"
              >
                {{ item.status }}
              </v-chip>
            </template>

            <template v-slot:item.avatar="{ item }">
              <v-avatar>
                <v-img :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}`"/>
              </v-avatar>
            </template>

            <template v-slot:item.price="{ item }">
              <span>{{ item.price | currency }}</span>
            </template>

            <template v-slot:item.cost="{ item }">
              <span>{{ item.cost | currency }}</span>
            </template>


            <template v-slot:item.button="{ item, header }">
              <!-- {{header}} -->
              <v-btn
                  :to="{
                                    name: header.button.to.name,
                                    params: {
                                            [header.button.to.params.key]: item[header.button.to.params.value],
                                            filter: header.button.to.params.filter,
                                            self:  item
                                        },
                                    }
                                "
                  block
                  :text="header.button.text"
                  :rounded="header.button.rounded"
                  :color="header.button.color"
              >
                <v-icon v-if="header.button.icon" left>{{ header.button.icon }}</v-icon>
                <v-badge
                    v-if="item[header.button.show_length]"
                    :value="item[header.button.show_length].length"
                    :content="item[header.button.show_length].length"
                >
                  {{ header.button.label }}
                </v-badge>
                <span v-else> {{ header.button.label }}</span>

                <!-- <span
                    class="grey--text"
                    v-if="header.button.show_length" >
                        {{item[header.button.show_length].length}}
                </span> -->
              </v-btn>
            </template>
            <template v-slot:item.createdAt="{ item, header }">
                            <span v-if="!header.time">
                                {{ $moment(item.createdAt).format('ll') }}
                            </span>
              <span v-else>
                                {{ item.createdAt | moment( 'hh:mm a') }}
                                ({{ $moment(item.createdAt).startOf('hour').fromNow() }})
                            </span>
            </template>
            <template v-slot:item.action="{ item }">

              <v-icon
                  color="success"
                  small
                  class="mr-3"
                  @click="editItem(item)"
              >
                edit
              </v-icon>
              <v-icon
                  color="error"
                  small
                  @click="deleteItem(item)"
              >
                delete
              </v-icon>
            </template>
            <!-- <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template> -->
          </v-data-table>
        </template>
      </ApolloQuery>
      <ApolloMutation
          v-if="removeQuery"
          :mutation="removeQuery"
          :variables="{
					where: {id: deletedItem.id},
				}"
          :update="deletedItemUpdate"
          @done="()=>{
					deletedItem = { show : false }
				}"
      >
        <template v-slot="{ mutate, loading, error }">
          <v-dialog
              v-model="deletedItem.show"
              width="500"
          >
            <v-card style="border-raduis: 32px">
              <v-card-title
                  class="white--text red"
                  primary-title
              >
                <v-row
                    justify="space-between"
                    align="start"
                >
                  <p>Delete {{ title }}</p>
                  <v-btn icon color="white" @click="deletedItem.show = false">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>

              <v-alert v-if="error" color="error">
                {{ error }}
                <pre>
                                    {{ deletedItem }}
                                </pre>
              </v-alert>

              <v-card-text class=" subtitle-1 pt-5">
                Deleted {{ title }} can not be retrived.
                Are you sure you want to delete the {{ title }}?
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"
                    color="red"
                    text
                    @click="mutate()"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </ApolloMutation>

    </v-layout>
  </v-container>
</template>
<script>
import FirebaseUpload from './FirebaseUploader'

export default {
  components: {FirebaseUpload},
  name: "GQLDataTable",
  props: {
    search_conditions: {type: Array, default: () => []},
    hide_add_button: {type: Boolean, default: () => false},
    result_name: String,
    icon: String,
    title: String,
    subtitle: String,
    validate: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    createFields: {
      type: Array,
      default: function () {
        return []
      }
    },
    connector: {type: Object, default: () => ({})},
    where: {type: Object, default: () => ({})},
    conditions: {type: Object, default: () => ({})},
    getQuery: Object,
    createQuery: Object,
    removeQuery: Object,
    updateQuery: Object,
    subscriptionQuery: Object,
  },
  data() {
    return {
      searchObj: {},
      search_phrase: '',
      img_upload_counter: 0,
      is_saving: false,
      create_item_dialog: false,
      is_update: false,
      deletedItem: {
        show: false
      },
      editedItem: {},
      defaultItem: {},
    }
  },

  computed: {
    formTitle() {
      return this.is_update === false ? `New ${this.title}` : `Edit ${this.title}`
    },
    variables() {
      this.search_conditions.forEach(condition => {
        this.searchObj[condition] = this.search_phrase
      })
      console.log('where', {where: {...this.where, ...this.conditions, ...this.searchObj}})
      return {where: {...this.where, ...this.conditions, ...this.expand(this.searchObj)}}
    },
    isDisabled() {
      // A hack to trick the computed prop to rerun when there is a change in image
      this.img_upload_counter
      let canDisable = false
      this.validate.forEach(field => {
        console.log('canDisable field', this.editedItem, this.editedItem[field])
        if (!this.editedItem[field])
          canDisable = true
      })
      console.log('canDisable', canDisable, this.validate)
      return canDisable
    }
  },

  watch: {
    create_item_dialog(val) {
      this.is_saving = false
      val || this.close()
    },
  },

  methods: {
    searchData() {
      console.log(this.variables)
    },
    setURL(res) {
      this.editedItem[res.name] = res.fileName
      this.img_upload_counter++
      console.log(this.editedItem)
    },
    editItem(item) {
      this.is_update = true
      console.log('item', item)
      this.editedItem = item
      this.create_item_dialog = true
    },

    deleteItem(item) {
      this.deletedItem = {show: true, ...item}
    },

    close() {
      this.img_upload_counter = 0
      this.create_item_dialog = false
      setTimeout(() => {
        this.editedItem = this.defaultItem
        this.is_update = false
      }, 300)
    },

    parseDotNotation(str, val, obj) {
      var currentObj = obj,
          keys = str.split("."),
          i, l = Math.max(1, keys.length - 1),
          key;

      for (i = 0; i < l; ++i) {
        key = keys[i];
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
      }

      currentObj[keys[i]] = val;
      delete obj[str];
    },

    expand(obj) {
      for (var key in obj) {
        if (key.indexOf(".") !== -1) {
          this.parseDotNotation(key, obj[key], obj);
        }
      }
      return obj;
    },
    createItem() {
      let data = {}
      this.createFields.forEach(field => {
        switch (field.type) {
          case 'number' :
            data[field.name] = parseInt(this.editedItem[field.name])
            break
          case 'float' :
            data[field.name] = parseInt(parseFloat(this.editedItem[field.name]).toFixed(2))
            break
          default :
            data[field.name] = this.editedItem[field.name]
        }
      })
      data = {...data, ...this.connector}
      data = this.expand(data)
      console.log('createQuery', data, this.editedItem, this.createFields)
      return this.$apollo.mutate({
        mutation: this.createQuery,
        variables: {
          data: data
        },
        update: (store, {data}) => {
          // Get local store query data
          const local = store.readQuery({
            query: this.getQuery,
            variables: this.variables,
          })
          console.log(local, data)

          // Store updated data to local store
          store.writeQuery({
            query: this.getQuery,
            variables: this.variables,
            data: {
              // Find first attribute name local storage of get query
              [Object.keys(local)[0]]:
              // Insert local result_names back with new updated result_name
                  local[this.result_name].concat(data[Object.keys(data)[0]])
            }
          })
        }
      }).then(res => {
        this.$emit('created', res.data)
        return res
      })
    },


    updateItem() {
      let data = {}
      this.createFields.forEach(field => {
        switch (field.type) {
          case 'number' :
            data[field.name] = parseInt(this.editedItem[field.name])
            break
          case 'float' :
            data[field.name] = parseInt(parseFloat(this.editedItem[field.name]).toFixed(2));
            break
          default :
            data[field.name] = this.editedItem[field.name]

        }
      })
      data = {...data, ...this.connector}
      data = this.expand(data)
      // this.createFields.forEach(field=>{
      //     data[field.name] = field.type == 'number' ?
      //         parseInt(this.editedItem[field.name]) :
      //         this.editedItem[field.name]
      // })
      console.log('hello', data, this.editedItem)
      return this.$apollo.mutate({
        mutation: this.updateQuery,
        variables: {
          where: {id: this.editedItem.id},
          data: data
        },
        update: (store, {data}) => {
          const local = store.readQuery({
            query: this.getQuery,
            variables: this.variables
          })
          const updatedData = data[Object.keys(data)[0]]

          local[this.result_name].forEach((item, index) => {
            if (item.id === updatedData.id) {
              item[index] = updatedData;
            }
          });
          store.writeQuery({
            query: this.getQuery,
            variables: this.variables,
            data: {...local}
          })
        }
      })
    },

    deletedItemUpdate(store, {data}) {
      let removedItem = data[Object.keys(data)[0]]
      console.log(removedItem, this.getQuery, data)
      let local = store.readQuery({
        query: this.getQuery,
        variables: this.variables
      })
      local[this.result_name] = local[this.result_name].filter(item => item.id !== removedItem.id)
      store.writeQuery({
        query: this.getQuery,
        variables: this.variables,
        data: {...local}
      })
    },

    save() {
      this.is_saving = true
      if (this.is_update === true) {
        this.updateItem().finally(_ => {
          this.close()
        })
      } else {
        this.createItem().finally(_ => {
          this.editedItem = {}
          this.close()
        })
      }
    },

    onSubscriptionUpdate(previousResults, {subscriptionData: {data}}) {
      // // The previous result is immutable
      // const newResult = {
      //     messages: [...previousResult.messages],
      // }
      // // Add the question to the list
      // newResult.messages.push(subscriptionData.data.messageAdded)
      // return newResult
      let result_key = Object.keys(data)
      let result_data = data[result_key]
      let previous_key = Object.keys(previousResults)
      switch (result_data.mutation) {
        case "UPDATED":
          previousResults[previous_key] = previousResults[previous_key].map(previousItem => {
            return previousItem !== result_data.node.id ? previousItem : result_data.node
          })
          break

        case "CREATED":
          previousResults[previous_key].unshift(result_data.node)
          break

      }
      console.log(result_key, result_data, previousResults)
    },
  },
  created() {
    console.log('test', {where: {node: {...this.conditions}}})
  }
}
</script>
